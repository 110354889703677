import { Environment } from './models/environment.interface';
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build -c val` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment: Environment = {
  appName: 'Data Insights',
  envName: 'VAL',
  production: false,
  apiBase: 'https://api.datainsights.val.ert.com',
  version: 'DI_2.5.1-05032024-20:03:52',
};
